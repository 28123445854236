<template>
    <div id = "myChatter">
  
      <div class="chat-popup" id ="myForm">
        <div class="header">
          <img src="../assets/images/seal.png" alt="SEAL" style="float: left;width:40px;height:40px;position: center;">
          <h1 style="width: 100%">&nbsp;&nbsp;UMD Virtual Agent</h1>
        </div>
  
        <ChatConnection ref = "chat"/>
      
      </div>
    </div>
  </template>
  <script>
  import ChatConnection from "@/components/ChatConnection";
  export default {
    components: {ChatConnection},
    mounted() {
      if(this.$route.query.size == "large"){
        document.getElementById("myForm").style.margin = "10px";
        document.getElementById("myForm").style.position = "static";
      }
      else {
        document.getElementById("myForm").style.maxHeight = "475px";
        document.getElementById("myForm").style.width = "320px";
      }
    },
  }
  </script>
  
  <style scoped>
  
  .header {
      display: flex;
      height: 45px;
      width: 100%;
      background-color: #e21833;
      color: white;
      text-align: left;
      padding-left: 25px;
      justify-content: left;
      align-items: center;
      font-size: 10px;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      font-weight: normal;
      font-family: sans-serif;
  }
  .chat-popup {
    position: fixed;
    bottom: 5px;
    right: 5px;
    border-radius: 8px;
  }
  
  </style>