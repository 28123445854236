<template>
    <div id="app">
      <router-view> </router-view>
    </div>
  </template>
  
  <script>
  //import ChatConnection from "@/components/ChatConnection";
  export default {
    // components: { ChatConnection },
  
  };
  </script>